<template>
  <div
    v-if="business && business.kyc"
    class="w-full flex items-center gap-x-3 bg-tertiary/5 px-5 py-4 rounded-lg mb-3"
  >
    <div class="min-w-2">
      <kyb-caution />
    </div>
    <div
      v-if="
        business?.kyc?.review_status === ReviewStatus.PENDING &&
        [
          IdentityKycVerificationState.CREATED,
          IdentityKycVerificationState.READY,
          IdentityKycVerificationState.IN_PROGRESS,
        ].includes(business.kyc.state)
      "
    >
      <p class="text-tertiary text-sm">
        <template v-if="business.business.country !== 'NG'">
          Complete your KYB to have unlimited access to Lync.
        </template>
        <template v-else>
          We can only provide <strong>NGN</strong> and stablecoin accounts to
          businesses registered in Nigeria. For <strong>USD</strong>,
          <strong>GBP</strong>, and <strong>EUR</strong> accounts, please use
          your entities registered in the US/UK/EU/CA for your KYB. Contact our
          support team at <strong>support@lync.global</strong> if you need help
          getting incorporated in the USA.
        </template>
        <button
          class="underline font-medium underline-offset-2 block"
          @click="toggleRequirementsModal"
        >
          Complete your KYB
        </button>
      </p>
    </div>
    <p
      v-else-if="
        business?.kyc?.review_status === ReviewStatus.PENDING &&
        business.kyc.state === IdentityKycVerificationState.IN_REVIEW
      "
      class="text-tertiary text-sm"
    >
      Your KYC is currently being reviewed. You will be notified once it is
      completed.
      <a
        v-if="business?.kyc?.verification?.url"
        :href="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2"
        target="_blank"
      >
        View your KYB
      </a>
    </p>
    <p
      v-else-if="
        business?.kyc?.review_status === ReviewStatus.CORRECTION_REQUESTED
      "
      class="text-tertiary text-sm"
    >
      A correction was requested on your KYB. Please make the necessary changes
      to continue using Lync.
      <a
        v-if="business?.kyc?.verification?.url"
        :href="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2"
        target="_blank"
      >
        Complete your KYB
      </a>
    </p>
    <p
      v-else-if="business?.kyc?.review_status === ReviewStatus.REJECTED"
      class="text-tertiary text-sm"
    >
      Your KYB was rejected. Please contact support for further assistance.
      <a
        v-if="business?.kyc?.verification?.url"
        :href="business?.kyc?.verification?.url"
        class="block w-fit underline font-medium underline-offset-2"
        target="_blank"
      >
        View your KYB
      </a>
    </p>
    <p
      v-else-if="business?.kyc?.review_status === ReviewStatus.APPROVED"
      class="text-tertiary"
    >
      Congratulations your KYB has been approved. You now have unlimited access
      to Lync.
    </p>
  </div>
  <app-modal
    v-if="business?.kyc?.verification?.url"
    :is-open="isRequirementsModalOpen"
    :handle-close="toggleRequirementsModal"
    size="xl"
  >
    <kyc-requirements-modal
      :requirements="kybRequirements"
      :kyb-link="business?.kyc?.verification?.url"
      :close-modal="toggleRequirementsModal"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { useLoggedInBusiness } from "@/composables/states";
import { IdentityKycVerificationState, ReviewStatus } from "@/types";
import { ref } from "vue";

const { business } = useLoggedInBusiness();

const isRequirementsModalOpen = ref(false);

const toggleRequirementsModal = () => {
  isRequirementsModalOpen.value = !isRequirementsModalOpen.value;
};

const kybRequirements = [
  {
    title: "Company Information",
    html: `
      <div class="requirement-section">
        <p style="margin: 10px 0">All documents and information must be current and reflect verifiable information:</p>
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0"><strong>Company Name:</strong> Full legal name of the business entity</li>
          <li style="margin: 8px 0"><strong>Registration Number:</strong> Official registration or incorporation number</li>
          <li style="margin: 8px 0"><strong>Country of Incorporation:</strong> Jurisdiction where the company is registered</li>
          <li style="margin: 8px 0"><strong>Business Address:</strong> Physical and mailing address of the registered office</li>
          <li style="margin: 8px 0"><strong>Contact Information:</strong> Primary contact details, including email and phone number</li>
        </ul>
      </div>
    `,
  },
  {
    title: "Business Registration Documents",
    html: `
      <div class="requirement-section">
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0"><strong>Certificate of Incorporation:</strong> Official document verifying the company's formation</li>
          <li style="margin: 8px 0"><strong>Memorandum & Articles of Association:</strong> Document outlining the structure, purpose, and operational rules of the company</li>
          <li style="margin: 8px 0"><strong>List of Directors:</strong> Full names, nationality, and residential addresses of all directors</li>
          <li style="margin: 8px 0"><strong>List of Ultimate Beneficial Owners (UBOs):</strong> Information on any individual with 5% or more ownership, including identification and proof of address.  
          </li>
          <li style="margin: 8px 0"><strong>TIN/EIN:</strong> Taxpayer Identification Number (TIN) or Employer Identification Number (EIN)</li>
          <li style="margin: 8px 0"><strong>Licenses or Permits:</strong> Required for companies in regulated industries, such as financial services</li>
        </ul>
      </div>
    `,
  },
  {
    title: "Director and Shareholder Information",
    html: `
      <div class="requirement-section">
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0">
            <strong>Director Information:</strong>
            <p style="margin: 10px 0">Identification (government-issued ID or passport) and proof of address (not older than three months) for each director. A register listing all Directors of the company and it must be signed and dated within 12 months from the time of submission.</p>
          </li>
          <li style="margin: 8px 0">
            <strong>Shareholder Register:</strong>
            <p style="margin: 10px 0">Shareholder Register accounting for 100% of the company's shares, fully allocated or not. Include names, percentage of ownership, and addresses of all shareholders. This must be dated within 12 months from the time of submission.
             <a href="https://drive.google.com/file/d/11Ws294WW5mc1CTOBJGP-cgk1-omTbb8G/view?usp=sharing" target="_blank" class="underline font-medium underline-offset-2">View a template</a>
            </p>
            
          </li>
          <li style="margin: 8px 0"><strong>Significant Shareholders:</strong> Identification and proof of address for shareholders with 5% ownership or more. <a href="https://drive.google.com/file/d/1G7pVgmmfJZj9PSJwX6bfenvqS98w8ykn/view" target="_blank" class="underline font-medium underline-offset-2">View a template</a></li>
          <li style="margin: 8px 0"><strong>Proof of Company Address:</strong> Acceptable documents include a recent utility bill, lease agreement, or other documentation showing the registered address (not older than three months) in the company's name</li>
        </ul>

       
      </div>
    `,
  },
  {
    title: "Proof of Source of Funds",
    html: `
      <div class="requirement-section">
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0"><strong>Audited Financial Statements:</strong> Typically from the last two years</li>
          <li style="margin: 8px 0"><strong>Recent Bank Statements:</strong> Statements from the last 3 to 6 months to demonstrate cash flow and operational financial health</li>
          <li style="margin: 8px 0"><strong>Equity Financing Documents:</strong> Investment documents, including share certificates and agreements with venture capitalists or other investors</li>
          <li style="margin: 8px 0"><strong>Business Sale Agreements:</strong> If funds originate from a business or asset sale, provide the sale agreement</li>
          <li style="margin: 8px 0"><strong>Dividend Payments:</strong> Documents evidencing regular dividend income</li>
          <li style="margin: 8px 0"><strong>Grants and Subsidies:</strong> Relevant government-issued documentation for grants or subsidies, applicable for startups or non-profits</li>
        </ul>
      </div>
    `,
  },
  {
    title: "Purpose of the Account",
    html: `
      <div class="requirement-section">
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0">Description of the business operations and intended account use, such as for third-party payments, foreign exchange, or trade financing</li>
          <li style="margin: 8px 0">The Types of Products and services you provide</li>
        </ul>
      </div>
    `,
  },
  {
    title: "Monthly Payment Volume",
    html: `
      <div class="requirement-section">
        <p style="margin: 10px 0">Disclosure of the expected monthly payment volume (e.g., from $50,000 to over $5,000,000)</p>
      </div>
    `,
  },
  {
    title: "KYC/AML Compliance",
    html: `
      <div class="requirement-section">
        <ul style="margin: 12px 0">
          <li style="margin: 8px 0"><strong>AML/CFT Policy:</strong> If applicable, provide an Anti-Money Laundering and Counter Financing of Terrorism policy document</li>
          <li style="margin: 8px 0"><strong>AML/CFT Compliance Attestation:</strong> Form attesting to the company's adherence to KYC and AML regulations</li>
        </ul>
      </div>
    `,
  },
  {
    title: "Onboarding Questionnaire",
    html: `
      <div class="requirement-section">
        <p style="margin: 10px 0">A fully completed Onboarding Questionnaire</p>
      </div>
    `,
  },
];
</script>
