<template>
  <div class="relative w-full bg-white rounded-[10px]">
    <header
      class="w-full flex justify-between items-center border-b-2 border-greyscale-7 px-5 py-4"
    >
      <h3 class="text-sm text-primary font-[800]">KYB requirements</h3>

      <button class="inline w-[16px] h-[18px]" @click="closeModal">
        <close-icon />
      </button>
    </header>

    <div class="w-full px-5 py-5 flex flex-col gap-y-5">
      <div
        class="w-full flex items-center gap-x-3 bg-tertiary/5 text-tertiary px-4 py-3 text-sm leading-tight rounded-lg"
      >
        <kyb-caution />
        <p>
          All documents and information provided must be correct and reflect
          verifiable information
        </p>
      </div>

      <div class="w-full flex flex-col gap-y-3">
        <requirements-collapse
          v-for="req in requirements"
          :key="String(req)"
          :title="req.title"
          :html="req.html"
        />
      </div>

      <label class="text-sm text-primary flex items-center gap-x-2">
        <input
          v-model="readRequirements"
          class="w-4 h-4 block"
          type="checkbox"
        />
        <span class="w-full">
          I’ve read the requirements for onboarding and ready to start KYB
        </span>
      </label>

      <a :disabled="!readRequirements" :href="kybLink" target="_blank">
        <app-button
          size="lg"
          variant="primary"
          :disabled="!readRequirements"
          class="w-full mt-2"
          >Start KYB</app-button
        >
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const readRequirements = ref(false);
defineProps<{
  closeModal: () => void;
  requirements: {
    title: string;
    html: string;
  }[];
  kybLink: string;
}>();
</script>
