<template>
  <aside
    class="fixed shadow-lg lg:shadow-none z-[50] top-0 left-0 lg:relative w-full max-w-[225px] min-w-[225px] h-full bg-greyscale-2 py-10 transition-transform duration-300 ease-in-out"
    :class="
      showSidebar ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
    "
  >
    <header class="px-9 relative">
      <div role="banner" class="w-[91px]">
        <img src="@/assets/images/lync-logo.svg" alt="Lync By Waza" />
      </div>
      <button
        class="block lg:hidden absolute right-4 -top-5 border-2 border-primary px-2 py-1 rounded-[5px]"
        @click="toggleSidebar"
      >
        <close-icon />
      </button>
    </header>
    <nav
      class="w-full flex gap-x-2 flex-col justify-start py-3 px-2 lg:px-9 mt-12 lg:mt-10"
    >
      <nav-link
        v-for="(it, index) in routes"
        :key="`${it.route + index}`"
        :active-icon="it.activeIcon"
        :in-active-icon="it.inActiveIcon"
        :name="it.name"
        :route="it.route"
        :active="
          route.path === '/'
            ? route.path === it.route
            : it.route.includes(route.matched[0].path)
        "
        :badge="
          isTransaction(it) &&
          approvalRequests &&
          approvalRequests.items?.length > 0
        "
      />

      <coming-soon-route name="Cards" :icon="CardsIcon" />
      <coming-soon-route name="Financing" :icon="FinancingIcon" />
      <coming-soon-route v-if="!canAccessDeals" name="Deals" :icon="DealsIcon" />

      <nav-link
        :active-icon="ActiveSettingsIcon"
        :in-active-icon="InactiveSettingsIcon"
        name="Settings"
        route="/settings"
        :active="route.path === '/settings'"
      />
    </nav>
    <!-- <hr class="hidden lg:block w-full h-[1px] mt-16" /> -->
    <!-- it.route.includes(route.matched[0].path) -->
    <!-- <div class="hidden lg:block w-full px-9 mt-4">
      <button
        class="w-full h-[42px] p-[12px] flex justify-start items-center gap-x-3"
        @click="handleSignout"
      >
        <div>
          <signout-icon />
        </div>
        <span class="text-sm font-[500] text-text-primary">Sign out</span>
      </button>
    </div> -->
  </aside>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { RouteProps } from "../props";
import {
  CardsIcon,
  FinancingIcon,
  ActiveSettingsIcon,
  InactiveSettingsIcon,
} from "../../icons";
import { useListApprovalRequests } from "@/data-access/transactions";
import { ref } from "vue";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";
import DealsIcon from "@/components/icons/DealsIcon.vue";
import { useFeature } from "@/composables/use-feature";

interface SidebarProps {
  routes: RouteProps[];
  showSidebar: boolean;
  toggleSidebar: () => void;
  handleSignout: () => void;
}

const route = useRoute();

defineProps<SidebarProps>();

const { hasPermission } = usePermission();
const { canAccessDeals: {
  value: canAccessDeals,
} } = useFeature();

const { data: approvalRequests } = useListApprovalRequests(
  {
    page: ref(1),
    limit: ref(100),
    filters: {
      state: ref(["PENDING"]),
    },
  },
  hasPermission(LyncPermissions.approval_requests_read),
);

const isTransaction = (item: RouteProps) => {
  return item.route.includes("/transactions");
};
</script>
