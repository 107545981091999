<template>
  <label class="w-full">
    <div
      class="w-full flex gap-y-[9px]"
      :class="alignRow ? 'flex-col md:flex-row gap-x-3' : 'flex-col'"
    >
      <div
        class="flex justify-between items-center"
        :class="alignRow ? 'w-fit min-w-max' : 'w-full'"
      >
        <span
          :class="`text-sm font-medium w-fit min-w-fit ${
            greyText ? 'text-primary/70' : 'text-primary'
          } ${
            showRequiredLabel && required
              ? `after:content-['*'] after:ml-0.5 after:text-red-500`
              : ''
          }`"
          >{{ label }}</span
        >
      </div>
      <select
        :class="`w-full h-10 border ${
          errorMessage ? 'border-[#F41414]' : 'border-[#E2E2E2]'
        } px-4 py-2 rounded-[5px] focus:outline-primary appearance-none bg-select-chevron bg-chevron-position bg-no-repeat bg-auto text-sm`"
        :required="required"
        :value="modelValue"
        v-bind="$attrs"
        @input="updateValue"
      >
        <slot />
      </select>
    </div>
    <span class="text-failed text-sm flex items-center gap-x-[2px] mt-1"
      ><caution-icon v-if="errorMessage" class="fill-failed min-w-[24px]" />
      {{ errorMessage && errorMessage }}</span
    >
  </label>
</template>

<script lang="ts">
export default {
  name: "AppSelect",
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
interface InputProps {
  modelValue?: string | number | undefined;
  label: string;
  required?: boolean;
  showRequiredLabel?: boolean;
  errorMessage?: string;
  alignRow?: boolean;
  greyText?: boolean;
}

defineProps<InputProps>();
const emit = defineEmits(["update:modelValue"]);

const updateValue = (event: Event) => {
  const target = event.target as
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;
  emit("update:modelValue", target.value);
};
</script>
