<template>
  <app-layout title="Settings" description="Manage your profile">
    <section class="w-full px-4 md:px-8 lg:pl-14 lg:pr-8">
      <TabGroup as="div" class="flex h-full flex-wrap gap-x-3 lg:flex-nowrap">
        <TabList
          class="px-0 flex flex-row lg:flex-col w-full lg:w-[20%] xl:w-[15%] gap-x-4 xl:max-w-[180px]"
        >
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-fit py-3 h-10 focus:outline-none text-sm ${
                selected
                  ? 'text-tertiary font-[800]'
                  : 'text-primary/60 font-medium'
              } `"
            >
              Password
            </button>
          </Tab>
          <Tab v-slot="{ selected }" as="template">
            <button
              :class="`w-fit py-3 h-10 rounded-[5px] focus:outline-none text-sm ${
                selected
                  ? 'text-tertiary font-[800]'
                  : 'text-primary/60 font-medium'
              } `"
            >
              2FA
            </button>
          </Tab>
          <Tab
            v-if="
              canAccess(LyncFeatures.USER_MANAGEMENT) &&
              hasPermission(LyncPermissions.users_read)
            "
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="`w-fit py-3 h-10 rounded-[5px] focus:outline-none text-sm ${
                selected
                  ? 'text-tertiary font-[800]'
                  : 'text-primary/60 font-medium'
              } `"
            >
              User Management
            </button>
          </Tab>
        </TabList>
        <TabPanels class="w-full lg:w-[80%] xl:w-[85%] py-3 h-full lg:px-5">
          <TabPanel class="w-full px-0"> <password-settings /> </TabPanel>
          <TabPanel class="w-full px-0">
            <factor-authentication-settings />
          </TabPanel>
          <TabPanel
            v-if="
              canAccess(LyncFeatures.USER_MANAGEMENT) &&
              hasPermission(LyncPermissions.users_read)
            "
            class="w-full px-0"
          >
            <user-management-settings />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </section>
  </app-layout>
</template>

<script setup lang="ts">
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import { LyncFeatures, useFeature } from "@/composables/use-feature";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const { canAccess } = useFeature();
const { hasPermission } = usePermission();
</script>
