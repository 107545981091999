import { TAddress } from "@/types";

export interface CreateFiatBeneficiary {
  country: string;
  beneficiary_name: string;
  email?: string;
  currency: string;
  method: {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details: Record<string, any>;
  };
  beneficiary_address: TAddress;
}

export interface CreateBankingBeneficiary {
  country: string;
  beneficiary_name: string;
  friendly_name?: string;
  kind: string;
  email?: string;
  currency: string;
  method: {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    details: Record<string, any>;
  };
  beneficiary_address?: TAddress;
}

export type FiatBeneficiaryView =
  | "beneficiary-info"
  | "beneficiary-address"
  | "beneficiary-bank";

export type BankingBeneficiaryView =
  | "beneficiary-info"
  | "beneficiary-address"
  | "beneficiary-bank"
  | "ngn-beneficiary"
  | "gbp-faster-payment"
  | "bank-address";

export enum UKNameCheckingAnswer {
  no_match = "no_match",
  full_match = "full_match",
  close_match = "close_match",
}

export enum UKNameCheckingReasonType {
  rejected = "rejected",
  warning = "warning",
  okay = "okay",
}
export interface UKNameCheckingResponse {
  answer: UKNameCheckingAnswer;
  actual_name: string | null;
  reason_code: string;
  reason: string;
  reason_type: UKNameCheckingReasonType;
  description: string;
}
