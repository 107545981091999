<template>
  <div class="w-full px-5 py-6">
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <combo-input
        label="Bank"
        :data="bankList"
        :default-value="values.bank_code"
        :error-message="errors.bank_code"
        required
        show-required-label
        @select="handleSelectedBank"
      />

      <app-input
        v-bind="formFields.account_number"
        :error-message="errors.account_number"
        type="text"
        label="Account number"
        name="account_number"
        placeholder="Enter account number"
        required
        show-required-label
      />

      <app-input
        v-bind="formFields.account_name"
        :error-message="errors.account_name"
        type="text"
        label="Account name"
        name="account_name"
        disabled
        placeholder="Enter account name"
        required
        show-required-label
      />

      <div class="flex justify-between gap-x-3">
        <app-button type="button" size="lg" variant="outlined" @click="goBack"
          >Back</app-button
        >
        <app-button
          :loading="submitting || validating"
          :disabled="submitting || validating"
          type="submit"
          size="lg"
          variant="primary"
          >Create</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive, ref, watch, computed } from "vue";
import * as yup from "yup";
import { CreateBankingBeneficiary, FiatBeneficiaryView } from "./type";
import apiClient, { bankingUrl } from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";
import { SelectInputData } from "@/components/shared/type";
import { NgnBanksData } from "@/types";

const props = defineProps<{
  switchForm: (view: FiatBeneficiaryView) => void;
  handleCreate: (data: CreateBankingBeneficiary) => void;
  submitting: boolean;
  formData: CreateBankingBeneficiary;
  updateFormData: (data: CreateBankingBeneficiary) => void;
  banksList: NgnBanksData[];
}>();

interface FormFields {
  bank_code: string;
  account_number: string;
  account_name: string;
}

const toast = useAppToast();
const validating = ref(false);

const bankList = computed(() => {
  return props.banksList.map((it) => ({
    label: it.name,
    value: it.nip_code,
  }));
});

const { errors, handleSubmit, defineInputBinds, values, setFieldValue } =
  useForm<FormFields>({
    validationSchema: yup.object({
      bank_code: yup.string().required().label("Bank"),
      account_number: yup
        .string()
        .length(10)
        .required()
        .label("Account number"),
      account_name: yup.string().required().label("Account name"),
    }),
    initialValues: props.formData.method.details,
  });

const handleSelectedBank = (val: SelectInputData) => {
  setFieldValue("bank_code", val.value);
};

watch(values, (newValues) => {
  props.updateFormData({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        ...props.formData.method.details,
        bank_code: newValues.bank_code,
        account_number: newValues.account_number,
        account_name: newValues.account_name,
      },
    },
  });
});

const onSubmit = handleSubmit((formValues) => {
  props.handleCreate({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        bank_code: formValues.bank_code,
        account_number: formValues.account_number,
        account_name: formValues.account_name,
      },
    },
  });
});

const validateAccount = async () => {
  if (values.account_number && values.bank_code) {
    validating.value = true;

    await apiClient
      .get(
        bankingUrl(
          `data/ngn/validate-account?account_number=${values.account_number}&nip_code=${values.bank_code}`,
        ),
      )
      .then((result) => {
        validating.value = false;
        setFieldValue("account_name", result.data.data.account_name);
      })
      .catch((err) => {
        validating.value = false;
        setFieldValue("account_name", "");
        toast.error(errorMessage(err), {
          position: "top-right",
        });
      });
  }
};

watch(
  () => values.account_number,
  (newVal) => {
    if (newVal.length === 10) {
      validateAccount();
    }
  },
);

const formFields = reactive({
  bank_code: defineInputBinds("bank_code"),
  account_number: defineInputBinds("account_number"),
  account_name: defineInputBinds("account_name"),
});

const goBack = () => {
  if (
    props.formData.currency === "NGN" &&
    props.formData.method.type === "NUBAN"
  ) {
    props.switchForm("beneficiary-info");
    return;
  }
  props.switchForm("beneficiary-address");
};
</script>
