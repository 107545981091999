<template>
  <div class="w-full px-5 py-6">
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <app-input
        v-bind="formFields.account_number"
        :error-message="errors.account_number"
        type="text"
        label="Account number"
        name="account_number"
        placeholder="Enter account number"
        required
        show-required-label
      />

      <app-input
        v-bind="formFields.sort_code"
        :error-message="errors.sort_code"
        type="text"
        label="Sort code"
        name="sort_code"
        placeholder="Enter sort code"
        required
        show-required-label
      />

      <app-input
        v-bind="formFields.bank_name"
        :error-message="errors.bank_name"
        type="text"
        label="Bank name"
        name="bank_name"
        placeholder="Enter bank name"
        required
        show-required-label
      />

      <div
        v-if="validationResult && validationResult.actual_name"
        class="w-full"
      >
        <label
          class="mb-[9px] inline-block text-sm font-medium w-fit min-w-fit text-primary"
          >Actual Beneficiary Name</label
        >
        <input
          class="border border-[#E2E2E2] px-4 py-2 rounded-[5px] focus:outline-primary w-full h-full text-sm text-primary placeholder:text-sm"
          :value="validationResult.actual_name"
          disabled
        />
      </div>

      <faster-payment-validation-result
        v-if="validationResult"
        :result="validationResult"
        :beneficiary-details="formData"
      />

      <div class="flex justify-between gap-x-3">
        <app-button type="button" size="lg" variant="outlined" @click="goBack"
          >Back</app-button
        >
        <app-button
          :loading="submitting || validating"
          :disabled="submitting || !canCreate"
          type="submit"
          size="lg"
          variant="primary"
          >Create</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { computed, reactive, ref, watch } from "vue";
import * as yup from "yup";
import {
  BankingBeneficiaryView,
  CreateBankingBeneficiary,
  UKNameCheckingReasonType,
  UKNameCheckingResponse,
} from "./type";
import apiClient from "@/helpers/apiClient";
import { errorMessage } from "@/helpers/error";
import { useAppToast } from "@/composables";

const props = defineProps<{
  switchForm: (view: BankingBeneficiaryView) => void;
  handleCreate: (data: CreateBankingBeneficiary) => void;
  submitting: boolean;
  formData: CreateBankingBeneficiary;
  updateFormData: (data: CreateBankingBeneficiary) => void;
}>();

interface FormFields {
  sort_code: string;
  account_number: string;
  bank_name: string;
}

const toast = useAppToast();
const validating = ref(false);
const validationResult = ref<UKNameCheckingResponse | null>(null);

const canCreate = computed(() => {
  if (validationResult.value) {
    return (
      validationResult.value.reason_type === UKNameCheckingReasonType.okay ||
      validationResult.value.reason_type === UKNameCheckingReasonType.warning
    );
  }
  return false;
});

const { errors, handleSubmit, defineInputBinds, values } = useForm<FormFields>({
  validationSchema: yup.object({
    sort_code: yup.string().required().label("Sort code"),
    account_number: yup.string().length(8).required().label("Account number"),
    bank_name: yup.string().required().label("Bank name"),
  }),
  initialValues: props.formData.method.details,
});

watch(values, (newValues) => {
  props.updateFormData({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        sort_code: newValues.sort_code,
        account_number: newValues.account_number,
        bank_name: newValues.bank_name,
      },
    },
  });
});

const onSubmit = handleSubmit((formValues) => {
  props.handleCreate({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        ...props.formData.method.details,
        sort_code: formValues.sort_code,
        account_number: formValues.account_number,
        bank_name: formValues.bank_name,
      },
    },
  });
});

const validateAccount = async () => {
  if (values.account_number && values.sort_code) {
    validating.value = true;

    const requestParams = new URLSearchParams();
    requestParams.append("account_number", values.account_number);
    requestParams.append("sort_code", values.sort_code);
    requestParams.append("type", props.formData.kind);

    if (props.formData.kind === "INDIVIDUAL") {
      const [firstName, lastName] = props.formData.beneficiary_name.split(" ");

      requestParams.append("first_name", firstName);
      requestParams.append("last_name", lastName);
    }
    requestParams.append("company_name", props.formData.beneficiary_name);

    await apiClient
      .get(`v1/data/gb/validate-account?${requestParams.toString()}`)
      .then((result) => {
        validating.value = false;
        validationResult.value = result.data.data;
      })
      .catch((err) => {
        validating.value = false;
        toast.error(errorMessage(err), {
          position: "top-right",
        });
      });
  }
};

watch(values, (newVal) => {
  if (newVal.account_number.length === 8 && newVal.sort_code.length === 6) {
    validateAccount();
  }
});

const formFields = reactive({
  sort_code: defineInputBinds("sort_code"),
  account_number: defineInputBinds("account_number"),
  bank_name: defineInputBinds("bank_name"),
});

const goBack = () => {
  props.switchForm("bank-address");
};
</script>
